<template>
  <div class="mx-3">
    <div v-if="authorizedAccess">
      <v-layout row wrap justify-space-between class="mt-2">
        <v-flex xs12 sm4 md4 lg4 v-if="!selected" class="pa-1">
          <v-select
            dense
            solo
            class="caption"
            :label="$t('select business')"
            item-text="businessName"
            item-value="id"
            :items="ownersBusiness"
            v-model="selectedShop"
            @change="onSelectShop"
          ></v-select>
        </v-flex>
        <v-flex xs12 sm4 md4 lg4 class="text-right pa-1">
          <v-text-field
            v-model="search"
            dense
            solo
            :placeholder="$t('search products')"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs4
          sm2
          md3
          lg3
          class="text-right pa-1"
          v-if="filteredCart.length > 0"
        >
          <v-btn
            x-small
            color="error darken-2"
            @click="openClearOrderDialog()"
            text
            >{{ $t("clear order") }}</v-btn
          >
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 class="text-right">
          <!--sort buttons-->

          <v-btn
            :color="
              button.selected
                ? $store.state.secondaryColor
                : $store.state.primaryColor
            "
            x-small
            outlined
            class="mx-1 mt-1"
            v-for="button in menu"
            :key="button.title"
            @click="sortBy(button.action)"
          >
            <span class="caption text-lowercase">{{ $t(button.title) }}</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex sm12 md8 lg9 class="mt-1">
          <v-alert class="whitish">
            <v-layout wrap row v-if="selected">
              <v-flex
                xs12
                sm6
                md4
                lg3
                v-for="item in filterProduct"
                :key="item.id"
                class="pa-1"
              >
                <v-card :loading="loading" class="mx-auto my-1" max-width="360">
                  <template slot="progress">
                    <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template>

                  <v-img
                    :src="item.avatar"
                    contain
                    height="100"
                    @click="selectItem(item)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        justify="center"
                        align="center"
                        class="fill-height"
                      >
                        <v-progress-circular
                          indeterminate
                          width="2"
                          :color="$store.state.primaryColor"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <v-card-title class="caption font-weight-bold">
                    <span @click="selectItem(item)">{{ item.name }}</span>
                    <v-spacer></v-spacer>
                    <span class="text-right">
                      <v-btn
                        x-small
                        :color="$store.state.secondaryColor"
                        dark
                        @click="
                          itemDescription(
                            item.avatar,
                            item.name,
                            item.price.selling,
                            item.description.about
                          )
                        "
                        >{{ $t("details") }}</v-btn
                      ></span
                    ></v-card-title
                  >

                  <v-card-text>
                    <v-row align="center" class="mx-0"> </v-row>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-actions @click="selectItem(item)">
                    <span class="smaller font-weight-black"
                      >{{ $t("stock") }}:
                      <code>{{ item.quantity.inStock }}</code></span
                    >
                    <v-spacer></v-spacer>

                    <span
                      class="smaller font-weight-black error--text text--darken-3 red lighten-5 rounded px-2"
                    >
                      {{ currencyFormat(item.price.selling) }}</span
                    >
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap row v-else-if="selectedShop">
              <v-flex
                xs12
                sm4
                :md3="filteredCart.length == 0 ? true : false"
                :md4="filteredCart.length > 0 ? true : false"
                :lg4="filteredCart.length > 0 ? true : false"
                v-for="item in filterProduct"
                :key="item.id"
                class="pa-1"
              >
                <v-card
                  :loading="loading"
                  class="mx-auto my-1"
                  max-width="374"
                  @click="selectItem(item)"
                >
                  <template slot="progress">
                    <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template>

                  <v-img :src="item.avatar" contain height="100">
                    <template v-slot:placeholder>
                      <v-row
                        justify="center"
                        align="center"
                        class="fill-height"
                      >
                        <v-progress-circular
                          indeterminate
                          width="2"
                          :color="$store.state.primaryColor"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <v-card-title
                    class="text-capitalize caption font-weight-bold"
                    >{{ item.name }}</v-card-title
                  >

                  <v-card-text>
                    <v-row align="center" class="mx-0"> </v-row>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-actions>
                    <span class="smaller font-weight-black"
                      >{{ $t("stock") }}:
                      <code>{{ item.quantity.inStock }}</code></span
                    >
                    <v-spacer></v-spacer>

                    <span
                      class="smaller font-weight-black error--text text--darken-3 red lighten-5 rounded px-2"
                    >
                      {{ currencyFormat(item.price.selling) }}</span
                    >
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>

            <h1 v-else class="text-center title grey lighten-3">
              {{ $t("select shop to view products") }}
            </h1>
          </v-alert>
        </v-flex>
        <!-- ############ start of cart################# -->

        <!-- ######New section###### -->
        <v-flex xs12 sm12 md4 lg3 class="mt-1">
          <v-card>
            <v-toolbar :color="$store.state.primaryColor" dark height="40">
              <v-toolbar-title>{{ $t("order") }}</v-toolbar-title>

              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
              <div v-for="item in filteredCart" :key="item.id">
                <v-layout
                  row
                  wrap
                  justify-space-between
                  class="body-2 mt-1 px-3"
                >
                  <v-flex xs12 sm6 md6 lg6>
                    <span class="text-capitalize font-weight-black">{{
                      item.item.name
                    }}</span
                    ><br />
                    <span class="caption font-weight-thin">{{
                      item.item.barcode
                    }}</span>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="text-right">
                    <v-btn depressed fab x-small @click="addItem(item)">
                      <v-icon :color="$store.state.primaryColor"
                        >add_circle</v-icon
                      >
                    </v-btn>
                    <v-btn depressed fab x-small @click="removeItem(item)">
                      <v-icon color="red">remove_circle</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class="body-2 px-3">
                  <v-flex xs12 sm6 md6 lg6>
                    <span class="font-weight-light">{{
                      $t("unit price")
                    }}</span>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="text-right">
                    <span class="font-weight-light">{{
                      currencyFormat(item.price)
                    }}</span>
                  </v-flex>
                </v-layout>

                <v-layout row wrap class="body-2 px-3">
                  <v-flex xs12 sm6 md6 lg6>
                    <span class="font-weight-light">{{ $t("quantity") }}</span>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="text-right">
                    <span class="font-weight-light">{{ item.qty }}</span>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class="body-2 px-3">
                  <v-flex xs12 sm6 md6 lg6>
                    <span class="font-weight-light">{{ $t("discount") }}</span>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="text-right">
                    <span class="font-weight-light">{{
                      currencyFormat(item.discount)
                    }}</span>
                  </v-flex>
                </v-layout>

                <v-layout row wrap class="body-2 px-3">
                  <v-flex xs12 sm6 md6 lg6>
                    <span class="font-weight-black">{{ $t("total") }}</span>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="text-right">
                    <span class="font-weight-bold">{{
                      currencyFormat(item.total)
                    }}</span>
                  </v-flex>
                </v-layout>
                <v-divider class="mt-3" style="color: black"></v-divider>
              </div>
              <div
                class="body-2 grey lighten-4 rounded"
                v-if="filteredCart.length > 0"
              >
                <v-layout row wrap class="px-3 mt-3">
                  <v-flex xs12 sm6 md6 lg6>
                    <span class="font-weight-light">{{ $t("subTotal") }}</span>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="text-right">
                    <span class="font-weight-black">{{
                      currencyFormat(subTotal)
                    }}</span>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class="px-3 mt-5">
                  <v-flex xs12 sm6 md6 lg6>
                    <span class="font-weight-light">{{ $t("tax") }}</span>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="text-right" v-if="vat.vat">
                    <span class="font-weight-black">{{
                      currencyFormat(Math.ceil(subTotal * (vat.vat / 100)))
                    }}</span>
                  </v-flex>
                </v-layout>

                <v-layout row wrap class="px-3 mt-5">
                  <v-flex xs12 sm6 md6 lg6>
                    <span class="font-weight-black">{{
                      $t("grand total")
                    }}</span>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 class="text-right">
                    <strong
                      class="font-weight-black pink lighten-5 my-2 px-1 rounded red--text text--darken-2"
                      >{{ currencyFormat(grandTotal) }}</strong
                    >
                  </v-flex>
                </v-layout>
              </div>
              <div v-else>
                <p class="font-weight-light text-center">
                  {{ $t("order is empty") }}
                </p>
              </div>

              <v-layout
                row
                wrap
                class="px-3 py-5 blue lighten-5 mt-5 footer"
                v-if="filteredCart.length > 0 && paymentTime == 'After Service'"
              >
                <v-flex xs12 sm12 md12 lg12 class="text-right">
                  <v-btn block outlined color="indigo" @click="tableEntry()">
                    {{ $t("submit order") }}
                    <v-icon></v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout
                row
                wrap
                class="px-3 py-5 blue lighten-5 mt-5 footer"
                v-if="
                  filteredCart.length > 0 && paymentTime == 'Before Service'
                "
              >
                <v-flex xs12 sm12 md12 lg12 class="text-right">
                  <v-btn block outlined color="indigo" @click="show = !show">
                    {{ $t("payment options") }}
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>

                  <v-expand-transition>
                    <div v-show="show">
                      <v-divider></v-divider>

                      <v-card-text
                        v-for="payment in paymentButtons"
                        :key="payment.id"
                      >
                        <v-btn
                          block
                          class="mx-1"
                          :color="payment.color"
                          outlined
                          @click="SelectPaymentOption(payment.id)"
                        >
                          {{ $t(payment.text) }}
                          <v-icon right>{{ payment.icon }}</v-icon>
                        </v-btn>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>

          <!-- ########### -->
          <!-- ################## -->
        </v-flex>
        <!-- ###############End of cart################ -->
      </v-layout>

      <v-dialog v-model="tableEntryDialog" max-width="400px">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ $t("table entry") }}
          </v-card-title>

          <v-card-text>
            <span class="font-weight-black">{{ $t("select a table") }}</span>
            <v-form ref="tableInfo">
              <v-autocomplete
                outlined
                dense
                clearable
                v-model="table"
                :rules="rules.emptyField"
                :items="tables"
              ></v-autocomplete>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              text
              @click="submitOrder()"
              v-if="paymentTime == 'After Service'"
              >{{ $t("submit") }}</v-btn
            >
            <v-btn
              color="primary"
              text
              @click="cashPayment()"
              v-else-if="paymentTime == 'Before Service' && !flutterwavePayment"
              >{{ $t("submit") }}</v-btn
            >
            <v-btn color="primary" text @click="mpesaPayment()" v-else>{{
              $t("submit")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="cashDialog" persistent max-width="600px">
        <CashPayment
          :cart="cart"
          :focus="cashDialog"
          :grandTotal="grandTotal"
          :selectedShop="selectedShop"
          @cashDialog="cashDialog = false"
          @clearCartEmit="clearCartEmit"
        ></CashPayment>
      </v-dialog>

      <v-dialog v-model="mpesaDialog" persistent max-width="600px">
        <MpesaPayment
          :cart="cart"
          :focus="mpesaDialog"
          :grandTotal="grandTotal"
          :selectedShop="selectedShop"
          @mpesaDialog="mpesaDialog = false"
          @clearCartEmit="clearCartEmit"
        ></MpesaPayment>
      </v-dialog>

      <v-snackbar
        v-model="snackErrorOutOfStock"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("this item is out of stock.") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorOutOfStock = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="orderNotSubmittedSnack"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("order not submitted. Please try again Later.") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="orderNotSubmittedSnack = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorChangeBusiness"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("you cannot change a business mid transaction") }}.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorChangeBusiness = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackSuccessOrder"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("order cleared") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessOrder = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="paybillNotset"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("paybill number is not set") }}.</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="paybillNotset = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="orderSubmittedSnack"
        top
        center
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("order submitted") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="orderSubmittedSnack = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="reducedStockNotification"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("stock alert sent") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="reducedStockNotification = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackInfo"
        top
        centered
        color="info lighten-5 info--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("sending stock alert...") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="info darken-3"
            @click="snackInfo = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="dialog" max-width="400" persistent>
        <v-card dark height="30">
          <v-card-text class="py-2">
            <v-progress-linear
              height="10"
              :v-model="progressValue"
              :buffer-value="progressValue"
              stream
              striped
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="clearOrderDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">{{ $t("clear order") }} </v-card-title>
          <v-card-text>{{
            $t("are you sure you want to clear the order?")
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="clearOrderDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn color="red darken-1" text @click="clearItems()"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ######################## ITEM DESCRIPTION################ -->
      <v-dialog v-model="itemDescriptionDialog" width="900">
        <v-card>
          <v-toolbar color="primary" class="text-h5" dark>{{
            $t("item description")
          }}</v-toolbar>
          <v-card-text class="black--text">
            <v-layout row wrap>
              <v-flex xs12 sm6 md5 lg5 class="mt-4">
                <v-card max-width="344">
                  <v-img :src="itemDescriptionImage" height="400px"></v-img>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 md7 lg7 class="mt-4">
                <span class="font-weight-black text-h4">
                  {{ itemDescriptionName }}</span
                >
                <v-divider></v-divider>
                <v-layout row wrap class="mt-3">
                  <v-flex class="">
                    <span class="font-weight-black orange--text text-h4 px-3">
                      {{ currencyFormat(itemDescriptionPrice) }}</span
                    >
                  </v-flex>
                </v-layout>

                <v-layout row wrap class="mt-7 px-3">
                  <p>
                    {{ itemDescriptionAbout }}
                  </p>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red" text @click="itemDescriptionDialog = false">
              {{ $t("close") }}
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="fill-height" v-else>
      <UnauthorizedAccess :user="user" />
    </div>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import jwtdecode from "jwt-decode";
import CashPayment from "./CashPayment";
import MpesaPayment from "./MpesaPayment";
import UnauthorizedAccess from "@/components/mijini/UnauthorizedAccess";

export default {
  components: {
    CashPayment,
    MpesaPayment,
    UnauthorizedAccess,
  },

  watch: {
    cart() {
      this.$store.commit("SET_CART", this.cart);
    },
  },

  data: () => ({
    loading: true,
    show: false,
    userAccess: "",
    user: "",
    paybillNotset: false,
    paybill: "",
    flutterwavePayment: false,
    authorizedAccess: false,
    itemDescriptionDialog: false,
    snackSuccessOrder: false,
    orderSubmittedSnack: false,
    orderNotSubmittedSnack: false,
    snackErrorOutOfStock: false,
    snackErrorChangeBusiness: false,
    snackInfo: false,
    clearOrderDialog: false,
    tableEntryDialog: false,
    cashDialog: false,
    mpesaDialog: false,
    paymentTime: "",
    progressValue: 0,
    dialog: false,
    grandTotal: 0,
    snackSuccess: false,
    notificationSent: [{ id: "", status: "" }],
    reducedStockNotification: false,
    stockQuantityNotification: "",
    itemDescriptionImage: "",
    itemDescriptionName: "",
    itemDescriptionPrice: "",
    itemDescriptionAbout: "",
    search: "",
    rules: {
      emptyField: [(v) => !!v || this.$t("please fill out this field")],
    },
    paymentButtons: [
      {
        id: 1,
        icon: "mdi-cash-multiple",
        text: "Cash",

        color: "#038cbe",
      },
      {
        id: 2,
        icon: "",
        text: "Mpesa and Credit card",
        color: "#ef6c00",
      },
    ],

    table: "",
    tables: ["table 1", "table 2", "table 3", "table 4", "table 5"],
    //mpesa variables
    consumer_key: "hoSyBTQEdDXgBHmXrYVhZrEimGXdpWkZ",
    consumer_secret: "2giCZRFoF1HZYr8J",

    MPESA_PAYMENT_URL:
      "https://cors-anywhere.herokuapp.com/https://api.flutterwave.com/v3/charges?type=mpesa",

    MPESA_STK_URL:
      "https://cors-anywhere.herokuapp.com/https://sandbox.safaricom.co.ke/mpesa/stkpush/v1/processrequest",

    passkey: "bfb279f9aa9bdbcf158e97dd71a467cd2e0c893059b10f78e6b72ada1ed2c919",
    BusinessShortCode: 174379,
    password: "",

    cart: [],
    filteredCart: [],
    vat: "",
    quantity: 1,

    order: {
      orderId: "",
      totalAmount: "",
    },

    products: [],
    categories: [],
    picture_selected: "",

    businesses: [],
    previewPic:
      "https://cdn.pixabay.com/photo/2014/04/03/00/42/house-309156_960_720.png",

    selectedShop: "",
    businessName: "Select Business",
  }),

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },
    selectedCartBusiness() {
      return this.$store.getters.getCurrentBusinessCart;
    },
    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },

    filterProduct() {
      return this.products.filter((val) => {
        return (
          val.barcode.includes(this.search.trim()) ||
          val.name.toLowerCase().includes(this.search.toLowerCase().trim()) ||
          val.description.brand
            .toLowerCase()
            .includes(this.search.toLowerCase().trim()) ||
          val.price.selling.includes(this.search.trim())

          // val.quantity.inStock.toString().includes(this.search.trim())
        );
      });
    },

    subTotal() {
      let amount = this.filteredCart.map((item) => item.total);
      return amount.length > 1
        ? amount.reduce((prev, next) => Number(prev) + Number(next))
        : amount[0] != null
        ? amount[0]
        : 0;
    },

    tables() {
      return [
        this.$t("table ") + "1",
        this.$t("table ") + "2",
        this.$t("table ") + "3",
        this.$t("table ") + "4",
        this.$t("table ") + "5",
      ];
    },
    menu() {
      return [
        {
          title: this.$t("all"),
          action: "all",
          selected: true,
        },
        {
          title: this.$t("entries"),
          action: "entries",
          selected: false,
        },
        {
          title: this.$t("desserts"),
          action: "desserts",
          selected: false,
        },
        {
          title: this.$t("main course"),
          action: "mainCourse",
          selected: false,
        },
        {
          title: this.$t("drinks"),
          action: "drinks",
          selected: false,
        },
      ];
    },
    header() {
      return [
        {
          text: this.$t("item"),
          value: "item",
          class: "blue-grey darken-3 white--text font-weight-medium",
        },

        {
          text: this.$t("price"),
          value: "price",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("remove"),
          value: "remove",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("Qty"),
          value: "qty",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("add"),
          value: "add",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("total"),
          value: "total",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },

  created() {
    this.getUser();
    this.checkIfCartIsEmpty();
    this.getSettings();
    this.getStock();
  },

  methods: {
    getStock() {
      this.loading = true;

      this.products = [];
      if (this.selected) {
        this.selectedShop = this.selected;
        db.collection("products")
          .where("businessID", "==", this.selected)
          .where("categoryID", "in", [
            "xZ1wilwhTCnnmNEzRSjP",
            "IaCjPn0udRmMQ1wGM4Pa",
            "HldlCSEullk1pYcWJKZk",
            "eV2GrcfnPkuqecSBNCXO",
            "mAnbTfisZmEtjxbhH9U6",
            "f4vGKtFkvpDQglOAnd36",
            "rVwZemF7xilVoPwu0wRR",
            "cDiYSoPc8nbGRXfQJOdv",
            "fm3slXIBzliW2ZxG4bXT",
            "kLobEEnlQNOOw7KtgcOV",
          ])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              //get only items that are in stock
              this.products.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            //get only items that are in stock
            this.products = this.products.filter(
              (item) => item.quantity.inStock > 0
            );

            this.$store.commit("SET_PRODUCTS_POS", this.products);
            this.loading = false;
          });
      } else if (this.selectedCartBusiness) {
        this.selectedShop = this.selectedCartBusiness;
        db.collection("products")
          .where("businessID", "==", this.selectedCartBusiness)
          .where("categoryID", "in", [
            "xZ1wilwhTCnnmNEzRSjP",
            "IaCjPn0udRmMQ1wGM4Pa",
            "HldlCSEullk1pYcWJKZk",
            "eV2GrcfnPkuqecSBNCXO",
            "mAnbTfisZmEtjxbhH9U6",
            "f4vGKtFkvpDQglOAnd36",
            "rVwZemF7xilVoPwu0wRR",
            "cDiYSoPc8nbGRXfQJOdv",
            "fm3slXIBzliW2ZxG4bXT",
            "kLobEEnlQNOOw7KtgcOV",
          ])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              //get only items that are in stock
              this.products.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            //get only items that are in stock
            this.products = this.products.filter(
              (item) => item.quantity.inStock > 0
            );

            this.$store.commit("SET_PRODUCTS_POS", this.products);
            this.loading = false;
          });
      }
    },
    getSettings() {
      db.collection("configurations")
        .doc(this.$store.state.employerId)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.stockQuantityNotification =
              snaps.data().stockQuantityNotification;
            this.paymentTime = snaps.data().paymentTime;

            //set paybill
            this.paybill = snaps.data().paybill;
          } else {
            //set 5 as minumum default
            this.stockQuantityNotification = 5;

            this.paymentTime = "After Service";
          }
        })
        .catch(() => {
          this.snackErrorConfig = true;
        });
      // ######
    },
    //check if cart is empty
    checkIfCartIsEmpty() {
      //keep contents of cart in a variable
      let tempCart = this.$store.state.cart;
      this.cart = [];

      console.log("filtered cart check cart is empty");
      console.log(this.filterCart());

      if (tempCart.length > 0) {
        //if there is something populate cart
        this.cart = this.$store.state.cart;

        this.filteredCart = this.filterCart();
        this.getVat();
      }
    },

    selectShop() {
      this.$router.push({ name: "SelectBusiness" });
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },

    onSelectShop() {
      if (this.filteredCart.length > 0) {
        this.snackErrorChangeBusiness = true;
      } else {
        this.getBusinessName(this.selectedShop);
        this.$store.commit("SET_SELECTED_BUSINESS_CART", this.selectedShop);

        this.products = [];

        db.collection("products")
          .where("businessID", "==", this.selectedShop)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              //get only items that are in stock
              if (doc.data().quantity.inStock > 0) {
                this.products.push({
                  id: doc.id,
                  ...doc.data(),
                });
              }
            });
            //keep products in vuex store
            this.$store.commit("SET_PRODUCTS_POS", this.products);
            this.loading = false;
          });
      }
    },

    getBusinessName(id) {
      let busId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );
      return busId.businessName;
    },

    selectItem(item) {
      //only process items where quantity is greater than 0
      if (item.quantity.inStock > 0) {
        //send notification is less than the set amount
        if (item.quantity.inStock <= this.stockQuantityNotification) {
          this.calculateStockSendNotification(item);
        }

        var updateType = "ReduceStock";
        this.getVat();
        var data = {
          username: this.$store.state.currentUser,
          id: item.id,
          item: item,
          price: item.price.selling,
          discount: item.price.discount,
          qty: this.quantity,
          total:
            (Number(item.price.selling) - Number(item.price.discount)) *
            Number(this.quantity),
        };

        var index = this.products.findIndex((item) => item.id == data.id);

        this.products[index].quantity.delivered++;
        this.products[index].quantity.inStock--;
        this.products[index].quantity.ordered++;
        this.products[index].quantity.remaining--;

        let selectedId = data.id;

        // var idInCart = this.cart.map((item) => item.id);

        var usernameInCart = this.cart.map((item) => item.username);

        if (usernameInCart.includes(selectedId)) {
          //find index of quantity

          var cartIndex = this.cart.findIndex((item) => item.id == selectedId);

          this.cart[cartIndex].qty++;

          setTimeout(this.calculateNewStock(updateType, item.id), 3000);

          //get current quantity of items in cart
          var currentquantity = this.cart.map((item) => item.qty);

          this.cart[cartIndex].total =
            (data.price - Number(item.price.discount)) *
            currentquantity[cartIndex];

          this.$store.commit("SET_CART", this.cart); //repopulate cart with its filtered items
          this.filterCart();
        } else {
          this.cart.push(data);
          //repopulate cart with its filtered items
          this.filterCart();
          setTimeout(this.calculateNewStock(updateType, item.id), 3000);
        }
      } else {
        this.snackErrorOutOfStock = true;
      }
    },
    //filter cart items to leave only items that belong to current user
    filterCart() {
      console.log("whole cart");
      console.log(this.$store.state.cart);
      this.filteredCart = this.$store.state.cart.filter(
        (item) => item.username == this.$store.state.currentUser
      );

      return this.filteredCart;
    },

    calculateStockSendNotification(item) {
      var itemId = item.id;
      var data;
      var itemIdInNotification = this.notificationSent.findIndex(
        (item) => item.id == itemId
      );

      //if notification has not been sent already, send notification
      if (itemIdInNotification == -1) {
        this.snackInfo = true;
        if (typeof item.item == "undefined") {
          data = {
            message:
              item.name +
              " is nearing out of stock. " +
              item.quantity.inStock +
              " " +
              "remaining.",
            ownerId: this.ownersBusiness[0].ownerId,
            dateTime: new Date(),
            status: "unread",
          };
        } else {
          data = {
            message:
              item.item.name +
              " is nearing out of stock. " +
              item.item.quantity.inStock +
              " " +
              "remaining.",
            ownerId: this.ownersBusiness[0].ownerId,
            dateTime: new Date(),
            status: "unread",
          };
        }

        db.collection("notifications")
          .add(data)
          .then(() => {
            this.snackInfo = false;

            var data = {
              id: item.id,
              status: true,
            };
            this.notificationSent.push(data);

            this.reducedStockNotification = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    calculateNewStock(updateType, itemId) {
      //get index of the selected product
      var index = this.products.findIndex((item) => item.id == itemId);

      if (updateType == "ReduceStock") {
        db.collection("products")
          .doc(itemId)
          .update(this.products[index])
          .then(() => {})
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
      } else if (updateType == "AddStock") {
        db.collection("products")
          .doc(itemId)
          .update(this.products[index])
          .then(() => {})
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
      }
    },

    removeItem(item) {
      var updateType = "AddStock";

      var data = {
        id: item.id,
      };

      var b = this.products.findIndex((item) => item.id == data.id);

      this.products[b].quantity.delivered--;
      this.products[b].quantity.inStock++;
      this.products[b].quantity.ordered--;
      this.products[b].quantity.remaining++;

      let index = this.filteredCart.findIndex((x) => x.id == item.id);

      if (item.qty > 1) {
        this.filteredCart[index].qty--;
        this.filteredCart[index].total =
          item.price * this.filteredCart[index].qty;

        this.calculateNewStock(updateType, item.id);
        this.getVat();
        this.$store.commit("SET_CART", this.filteredCart);
      } else {
        this.calculateNewStock(updateType, item.id);
        this.getVat();
        this.filteredCart.splice(index, 1);
      }
    },

    addItem(item) {
      if (item.item.quantity.inStock > 0) {
        //send notification is less than set amount
        if (item.item.quantity.inStock <= this.stockQuantityNotification) {
          this.calculateStockSendNotification(item);
        }
        var updateType = "ReduceStock";
        var data = {
          id: item.id,
        };
        var b = this.products.findIndex((item) => item.id == data.id);
        this.products[b].quantity.delivered++;
        this.products[b].quantity.inStock--;
        this.products[b].quantity.ordered++;
        this.products[b].quantity.remaining--;
        let index = this.filteredCart.findIndex((x) => x.id == item.id);
        this.filteredCart[index].qty++;
        this.filteredCart[index].total =
          item.price * this.filteredCart[index].qty;
        setTimeout(this.calculateNewStock(updateType, item.id), 3000);
        this.getVat();
        this.$store.commit("SET_CART", this.filteredCart);
      } else {
        this.snackErrorOutOfStock = true;
      }
    },
    openClearOrderDialog() {
      this.clearOrderDialog = true;
    },

    clearItems() {
      this.clearOrderDialog = false;

      if (this.filteredCart.length > 0) {
        this.dialog = true;
        this.progressValue = 10;
        this.products = [];

        for (var index = 0; index < this.filteredCart.length; index++) {
          this.progressValue = 50;
          var cartId = this.filteredCart[index].id;
          var pId = this.products.findIndex((item) => item.id == cartId);

          var oldDelivered =
            this.products[pId].quantity.delivered -
            this.filteredCart[index].qty;
          var oldInStock =
            this.products[pId].quantity.inStock + this.filteredCart[index].qty;
          var oldOrdered =
            this.products[pId].quantity.ordered - this.filteredCart[index].qty;
          var oldRemaining =
            this.products[index].quantity.remaining +
            this.filteredCart[index].qty;
          this.products[pId].quantity.delivered = oldDelivered;
          this.products[pId].quantity.inStock = oldInStock;
          this.products[pId].quantity.ordered = oldOrdered;
          this.products[pId].quantity.remaining = oldRemaining;
          this.progressValue = 70;

          //splice data in the store
          //splice data in the store
          var cId = this.$store.state.cart.findIndex(
            (item) =>
              item.id == cartId &&
              item.username == this.$store.state.currentUser
          );

          this.$store.state.cart.splice(cId - 1, 1);
        }
        this.filteredCart = [];
        this.grandTotal = "";

        this.progressValue = 100;
        this.dialog = false;
        this.snackSuccessOrder = true;
        //remove all products where stock is 0
        this.spliceData();
      } else {
        //clear cart data and totals
        this.filteredCart = [];
        this.grandTotal = "";
        //get only items that are in stock
        this.products = this.products.filter(
          (item) => item.quantity.inStock > 0
        );
        this.loading = false;
      }
    },
    spliceData() {
      var ind = this.products.findIndex((item) => item.quantity.inStock == 0);

      //splice the items where stock is 0
      this.products.splice(ind, 1);
    },
    clearCartEmit() {
      if (this.paymentTime == "Before Service") {
        this.submitOrder();

        //clear cart data and totals
        this.filteredCart = [];
        this.cart = [];
        this.grandTotal = "";
      } else {
        //clear cart data and totals
        this.filteredCart = [];
        this.cart = [];
        this.grandTotal = "";
      }
    },

    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (
        this.user.accessLevel.isOwner ||
        this.user.accessLevel.isManager ||
        this.user.accessLevel.isWaiter
      )
        this.authorizedAccess = true;
      else this.authorizedAccess = false;
    },

    // get VAT function
    getVat() {
      var country = this.user.address.country;
      db.collection("vat")
        .where("country", "==", country)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.vat = doc.data();
          });

          //calculate grand total
          this.grandTotal =
            Number(this.subTotal) +
            Math.ceil(Number(this.subTotal * (this.vat.vat / 100)));
        });
    },

    //send order to database
    submitOrder() {
      if (this.$refs.tableInfo.validate()) {
        if (this.paymentTime == "Before Service") {
          //generate orderID
          //insert ittems into order
          this.order.foodItems = this.cart;
          this.order.cooked = false;
          this.order.totalAmount = this.grandTotal;
          this.order.table = this.table;
          this.order.businessId = this.selectedShop;
          this.order.dateCreated = new Date();

          this.order.orderId = Math.floor(Math.random() * 1000000000000);
          this.tableEntryDialog = false;
          //define status of current order
          this.order.status = true;
          this.order.paid = true;
          this.progressValue = 50;
          this.dialog = true;

          db.collection("orders")
            .doc(String(this.order.orderId))
            .set(this.order)
            .then(() => {
              this.progressValue = 100;

              this.dialog = false;
              this.orderSubmittedSnack = true;

              //clear cart data and totals
              this.cart = [];
              this.grandTotal = "";
            })
            .catch((error) => {
              this.orderNotSubmittedSnack = true;
              console.error("Error updating document: ", error);
            });
        } else {
          //generate orderID
          //insert ittems into order
          this.order.foodItems = this.cart;
          this.order.cooked = false;
          this.order.totalAmount = this.grandTotal;
          this.order.table = this.table;
          this.order.businessId = this.selectedShop;
          this.order.dateCreated = new Date();

          this.order.orderId = Math.floor(Math.random() * 1000000000000);
          this.tableEntryDialog = false;
          //define status of current order
          this.order.status = true;
          this.order.paid = false;
          this.progressValue = 50;
          this.dialog = true;

          db.collection("orders")
            .doc(String(this.order.orderId))
            .set(this.order)
            .then(() => {
              this.progressValue = 100;

              this.dialog = false;
              this.orderSubmittedSnack = true;

              //clear cart data and totals
              this.cart = [];
              this.grandTotal = "";
            })
            .catch((error) => {
              this.orderNotSubmittedSnack = true;
              console.error("Error updating document: ", error);
            });
        }
      }
    },

    tableEntry() {
      if (this.$store.state.cart.length > 0) {
        this.tableEntryDialog = true;
      }
    },

    SelectPaymentOption(id) {
      this.show = false;
      if (id == 1) {
        if (this.paymentTime == "Before Service") {
          this.tableEntry();
        }
      } else if (id == 2) {
        this.flutterwavePayment = true;
        if (this.paymentTime == "Before Service") {
          this.tableEntry();
        } else {
          this.mpesaPayment();
        }
      } else this.snackPayment = true;
    },

    //mpesa processing function
    async mpesaPayment() {
      if (this.paybill != "") {
        this.mpesaDialog = true;
      } else {
        this.paybillNotset = true;
      }
    },

    cashPayment() {
      this.cashDialog = true;
    },
    //item details to be displayed on the item description dialog
    itemDescription(image, name, price, about) {
      this.itemDescriptionImage = image;
      this.itemDescriptionName = name;
      this.itemDescriptionPrice = price;
      this.itemDescriptionAbout = about;

      this.itemDescriptionDialog = true;
    },

    sortBy(prop) {
      this.loading = true;
      var new_array;
      if (prop == "all") {
        this.menu[0].selected = true;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        //get all products from vuex store
        this.products = this.$store.state.productsPos;

        this.loading = false;
      } else if (prop == "entries") {
        this.menu[0].selected = false;
        this.menu[1].selected = true;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.products = this.$store.state.productsPos;
        new_array = this.products.filter(
          (element) =>
            element.categoryID == "IaCjPn0udRmMQ1wGM4Pa" ||
            element.categoryID == "mAnbTfisZmEtjxbhH9U6" ||
            element.categoryID == "xZ1wilwhTCnnmNEzRSjP"
        );
        this.products = new_array;

        this.loading = false;
      } else if (prop == "desserts") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = true;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.products = this.$store.state.productsPos;
        new_array = this.products.filter(
          (element) =>
            element.categoryID == "IaCjPn0udRmMQ1wGM4Pa" ||
            element.categoryID == "mAnbTfisZmEtjxbhH9U6" ||
            element.categoryID == "xZ1wilwhTCnnmNEzRSjP" ||
            element.categoryID == "rVwZemF7xilVoPwu0wRR"
        );

        //store filtered array back into products
        this.products = new_array;

        this.loading = false;
      } else if (prop == "mainCourse") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = true;
        this.menu[4].selected = false;
        this.products = this.$store.state.productsPos;
        new_array = this.products.filter(
          (element) =>
            element.categoryID == "HldlCSEullk1pYcWJKZk" ||
            element.categoryID == "eV2GrcfnPkuqecSBNCXO"
        );

        //store filtered array back into products
        this.products = new_array;

        this.loading = false;
      } else {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = true;
        this.products = this.$store.state.productsPos;
        new_array = this.products.filter(
          (element) =>
            element.categoryID == "xZ1wilwhTCnnmNEzRSjP" ||
            element.categoryID == "mAnbTfisZmEtjxbhH9U6" ||
            element.categoryID == "f4vGKtFkvpDQglOAnd36"
        );
        //store filtered array back into products
        this.products = new_array;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.whitish {
  background-color: #fafafa;
}
#search {
  width: 100%;
}

.smaller {
  font-size: 11px !important;
}
.footer {
  position: sticky;
  bottom: 20px;
}
</style>
